import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';

import { subscribeToDeviceChanging } from '../../observer/deviceObserver';
import { getDeviceType } from '../../helpers/browser';
import getStateValue from '../../helpers/getStateValue';

import { getDeviceRatio } from '../../../device';
import adjustToDevice from '../../../components/Instagram/utils';
import { DEVICE_TYPES, GRID_SIZE } from '../../../components/Instagram/constants';
import {
  INSTAGRAM_IMAGE_CLASS_NAME,
  INSTAGRAM_IMAGE_PLACEHOLDER_COUNT,
  INSTAGRAM_IMAGE_TEMPLATE,
  INSTAGRAM_IMAGE_PLACEHOLDER_TEMPLATE,
} from './constants';
import defaultImage from '../Image/utils';

class Instagram extends WidgetWrapper {
  init = () => {
    this.elInstagram = dom.getCollection(this.selector);

    if (!this.elInstagram.length) return;

    this.elInstagram.forEach(async (instagram) => {
      const settings = get(instagram, 'dataset.settings', null);

      if (isNil(settings)) return;

      await this.renderPosts(instagram, JSON.parse(settings));
    });
  };

  instagramMedia = async (href, element, retry = 0) => {
    try {
      const response = await fetch(href);

      if (response.status === 200 || response.status === 201) {
        const text = await response.text();

        return JSON.parse(text);
      }

      throw response;
    } catch (e) {
      if (retry === 5) return e?.status !== 404 ? { status: 'server-error' } : null;

      const media = await this.instagramMedia(href, element, retry + 1);

      return media;
    }
  };

  renderEmptyPlace = (element, className) => {
    const images = new Array(INSTAGRAM_IMAGE_PLACEHOLDER_COUNT).fill(1).map((_, index) => ({
      id: index,
      isPlaceholder: true,
    }));

    subscribeToDeviceChanging(element, this.onResize(element));

    images.forEach((image) => {
      const item = this.createImageElement(
        image,
        { className },
      );

      element.appendChild(item);

      const itemSize = this.getImageSizeForDevice(element, getDeviceType());

      dom.updateStyle(item, itemSize);
    });

    dom.show(element);
    dom.hideOpacity(element);
  }

  prepareImages = (images = [], amount = 1) => images
    .slice(0, amount)
    .map(({
      id, media_url: src, permalink, caption,
    }) => ({
      id,
      src,
      permalink,
      caption,
    }));

  renderPosts = async (element, settings) => {
    const {
      accountId,
      amount,
      targetBlank,
      className,
      showCaption,
    } = settings;

    const embedApiService = getStateValue(['apiUrls', 'embedApiService'], false);

    if (!accountId || !embedApiService) {
      this.renderEmptyPlace(element, className);

      return;
    }

    const href = `${embedApiService}/feed/instagram/${accountId}`;
    const media = await this.instagramMedia(href, element);

    if (isNil(media) || isEmpty(media)) return;

    let options = {
      className,
      shouldShowCaption: showCaption,
      shouldOpenInNewTab: targetBlank,
    };
    let preparedImages = [];

    if (media?.status === 'server-error') {
      const elErrorWrap = element.nextSibling;

      options = {};
      preparedImages = new Array(INSTAGRAM_IMAGE_PLACEHOLDER_COUNT).fill(1).map((_, index) => ({
        id: index,
        isPlaceholder: true,
      }));

      dom.show(elErrorWrap);
    } else {
      preparedImages = this.prepareImages(media, amount);
    }

    if (isEmpty(preparedImages)) {
      this.renderEmptyPlace(element, className);

      return;
    }

    subscribeToDeviceChanging(element, this.onResize(element));
    dom.show(element);

    preparedImages.forEach((image) => {
      const item = this.createImageElement(
        image,
        options,
      );

      element.appendChild(item);

      const itemSize = this.getImageSizeForDevice(element, getDeviceType());

      dom.updateStyle(item, itemSize);
    });
  };

  createImageElement = (
    image = {},
    {
      className = '',
      shouldShowCaption = false,
      shouldOpenInNewTab = true,
    } = {},
  ) => {
    if (!isPlainObject(image) || isEmpty(image)) return null;

    const {
      caption, src, permalink, isPlaceholder,
    } = image;
    const elItem = dom.createElement('div');
    const templateName = isPlaceholder
      ? INSTAGRAM_IMAGE_PLACEHOLDER_TEMPLATE
      : INSTAGRAM_IMAGE_TEMPLATE;

    dom.addClass(elItem, INSTAGRAM_IMAGE_CLASS_NAME);
    dom.addHtml(elItem, templateName(
      permalink,
      src,
      caption,
      shouldOpenInNewTab,
      shouldShowCaption,
      className,
    ));

    const elImg = dom.getElement('img', elItem);
    const elErrorWrap = dom.getElement('._error-wrap', elItem);

    elImg.onerror = () => {
      elImg.src = defaultImage;
      dom.addClass(elErrorWrap, 'picture-wrap_unavailable');
      dom.addClass(elErrorWrap, 'picture-wrap_unavailable_hide');
    };

    return elItem;
  };

  resize = (widget, deviceType) => {
    const imageSize = this.getImageSizeForDevice(widget, deviceType);
    const elImages = dom.getCollection(`.${INSTAGRAM_IMAGE_CLASS_NAME}`, widget);

    elImages.forEach((image) => dom.updateStyle(image, imageSize));
  };

  getImageSizeForDevice = (widget, deviceType) => {
    const { settings = {} } = widget.dataset;
    const {
      itemSize = GRID_SIZE,
      amountInRow = 3,
    } = JSON.parse(settings);

    const adjustSize = adjustToDevice(itemSize, deviceType);
    const deviceRatio = getDeviceRatio(deviceType, DEVICE_TYPES);

    const count = Math.ceil((adjustSize / GRID_SIZE) * amountInRow * deviceRatio);

    return { width: `${100 / count}%` };
  };

  onResize = (widget) => (deviceType) => this.resize(widget, deviceType);
}

export default Instagram;
